.content {
    background: #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    /*border: 1px solid red;*/
    width: 100%;
    /*padding: 30px 0px 20px 0px;*/
    flex-direction: column;
    max-width: 1420px;
    margin: 0 auto;
}

.figure_content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    grid-row-gap: 50px;
}

.figure_box {
    overflow: auto;
    padding-right: 30px;
    position: relative;
    background: #EAEAEA;
}

.figure_box_content {
    max-height: 250px;
    min-height: 250px;
    /*overflow: auto;*/
    min-width: 550px;


}

.figure_box::-webkit-scrollbar-thumb:vertical {
    background: #EAEAEA;
    border-radius: 5px;
    border: 0.5px solid #1F1E1E;
}

.figure_box::-webkit-scrollbar {
    height: 7px;
    width: 18px;
    border-radius: 5px;
    background: #fff;
}

.figure_box::-webkit-scrollbar-thumb:horizontal {
    background: #EAEAEA;
    border-radius: 5px;
    border: 0.5px solid #1F1E1E;
}

.figure_box_header {
    display: flex;
    align-items: center;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    background: #EAEAEA;
}

.figure_box_header_title {
    margin-right: 30px;
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
}

.figure_box_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
}

.figure_box_jud {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    grid-column-gap: 10px;
    position: sticky;
    left: 0;
    top: 23px;
    background: #EAEAEA;
    z-index: 2;
    /*padding-right: 38px;*/
}

.figure_box_content_item_sub {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr;
    grid-column-gap: 10px;
    margin-top: 10px;
    min-height: 42px;
}

.figure_box_jud div {
    color: var(--black, #1F1E1E);
    min-width: 50px;
    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    margin-top: 11px;
    margin-bottom: 15px;
}

.figure_box_content_item {

}

.figure_box_content_item_top {
    display: grid;
    grid-template-columns: .17fr 1.05fr 0.6fr;
    grid-column-gap: 8px;
}

.figure_box_content_item_number, .figure_box_content_item_name, .figure_box_content_item_city {
    border-radius: 3px;
    background: var(--white, #FFF);
    padding: 8px 8px;
    display: flex;
    align-items: center;
    min-width: 100%;
}

.figure_box_content_item_number p, .figure_box_content_item_name p, .figure_box_content_item_city p {
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.figure_box_content_item_number {
    min-width: 50px;
}

.figure_box_content_item_number p {
    color: var(--black, #1F1E1E);
    text-align: center;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 508;
    line-height: normal;
    letter-spacing: 0.9px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.score_full {
    border-radius: 3px;
    background: var(--white, #FFF);
    padding: 8px 10px;
}

.disconnect {
    color: red;
    animation: colored 1s infinite linear;
    cursor: pointer;
}

.connect {
    color: #4c4cf6 !important;
    cursor: pointer;
}

@keyframes colored {
    0% {
        color: rgba(255, 0, 0, 1);
    }
    10% {
        color: rgba(255, 0, 0, .90);
    }
    20% {
        color: rgba(255, 0, 0, .80);
    }
    30% {
        color: rgba(255, 0, 0, .70);
    }
    40% {
        color: rgba(255, 0, 0, .60);
    }
    50% {
        color: rgba(255, 0, 0, 0);
    }
    60% {
        color: rgba(255, 0, 0, .60);
    }
    70% {
        color: rgba(255, 0, 0, .70);
    }
    80% {
        color: rgba(255, 0, 0, .80);
    }
    90% {
        color: rgba(255, 0, 0, .90);
    }
    100% {
        color: rgba(255, 0, 0, 1);
    }
}
